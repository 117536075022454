<template>
	<div class="main-layout">
		<TheHeader />
		<v-container fluid>
			<router-view></router-view>
		</v-container>
		<Snackbar ref="snackbar" />
	</div>
</template>

<script>
import Snackbar from "@/components/app/Snackbar";
import TheHeader from "@/components/TheHeader";
export default {
	name: "MainLayout",
	components: {
		TheHeader,
		Snackbar,
	},
	mounted() {
		this.$root.snackbar = this.$refs.snackbar;
	},
};
</script>

<style>
.main-layout {
	background: #f5f5f5;
}
</style>
