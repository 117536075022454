<template>
	<v-menu
		v-model="open"
		class="the-header-profile"
		:close-on-content-click="true"
		:nudge-width="100"
		offset-y
	>
		<template v-slot:activator="{ on, attrs }">
			<div class="header__user" v-bind="attrs" v-on="on">
				<!-- Profile picture -->
				<v-avatar class="user-img" size="45">
					<v-img
						lazy-src="@/assets/user.svg"
						v-if="avatarLoaded"
						:src="avatarSrc"
						alt="profilepic"
					></v-img>
				</v-avatar>
				<!-- <img class="user-img" src="@/assets/user.svg" alt="logo" /> -->

				<!-- Dropdown arrow -->
				<img
					class="user-polygon"
					:class="{ rotate: open }"
					src="@/assets/polygon.svg"
					alt="logo"
				/>
			</div>
		</template>

		<v-card>
			<v-list>
				<v-list-item to="/user">
					<v-list-item-avatar>
						<v-icon color="primary">mdi-account</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Профиль</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<v-list>
				<v-list-item to="/logout">
					<v-list-item-avatar>
						<v-icon color="primary">mdi-logout</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Выйти</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
	</v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "TheHeaderProfile",
	data() {
		return {
			open: false,
			avatarLoaded: false,
    	avatarSrc: "@/assets/user.svg"
		};
	},
	computed: {
		...mapGetters({
			userData: "auth/GET_INDIVIDUAL_DATA",
		}),
	},
	methods: {
		async loadAvatar() {
			try {
				console.log('this.userData.avatar',this.userData.avatar)
				this.avatarSrc = await this.loadServerImage(this.userData.avatar);
				console.log('avatarSrc', this.avatarSrc)
				this.avatarLoaded = true;
			} catch (error) {
				console.error('Ошибка при загрузке аватара:', error);
				// Установить фоллбэк, если есть ошибка
				this.avatarSrc = "@/assets/user.svg";
				this.avatarLoaded = true;
			}
		}
	},
	created() {
		this.loadAvatar()
	},
};
</script>

<style lang="scss" scoped>
.header__user {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.user-img {
	margin-right: 32px;
}
.user-polygon {
	transition: all 0.3s ease;
	&.rotate {
		transform: rotate(180deg);
	}
}
</style>
